import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const HausbauKosten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Hausbau – Die Kosten im Überblick" showCalc={false} />
            <Article>
                <p>
                    Es ist auch dein Traum, den eigenen Wohnraum, wenn schon nicht eigenhändig, so doch nach deinen
                    eigenen Vorstellungen zu errichten? Wenn du wissen willst, mit welchen Kosten du dabei rechnen
                    musst, bist du hier genau richtig – wir gehen sie Schritt für Schritt mit dir durch.
                </p>
                <hr />

                <h2>Welche Kostenanteile muss ich beim Hausbau berücksichtigen?</h2>
                <p>
                    Zunächst sind das die Kosten für das Grundstück selbst sowie die beim Erwerb anfallenden
                    Nebenkosten. Dieser Kostenanteil ist abhängig von der Lage des Baugrunds. Des Weiteren entstehen
                    Kosten für die Herstellung, also den eigentlichen Bau deines neuen Hauses. Hierzu zählen die
                    Einrichtung der Baustelle, Erdarbeiten, Roh- und Innenausbau, Dachdeckung, der Ausbau des Kellers
                    sowie die Installation von Außenanlagen. Berücksichtige auch hier die Nebenkosten: Etwa Honorare für
                    Bauleitung und Architekt*in, Kosten für die Baubewilligung, die Erschließung und Vermessung des
                    Grundstücks sowie für das Bodengutachten. Die Bauversicherung wird ebenfalls zu diesen Nebenkosten
                    gerechnet. Schließlich bringt noch der Kredit, den du für die Realisierung deines Wohntraums
                    aufnimmst, Finanzierungskosten mit sich. Vor der Aufnahme eines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    solltest du dich außerdem genau über die unterschiedlichen Kreditarten informieren, um die für deine
                    Lebenssituation am besten geeignetste Variante zu finden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Was beeinflusst die Gesamtkosten des Hauses?</h2>
                <ul>
                    <li>
                        Die <strong>Lage</strong> des Grundstücks: Sowohl die Grundstückspreise als auch die Kosten für
                        den Hausbau sind im urbanen Gebiet deutlich höher als in der ländlichen Region – insbesondere im
                        Innenstadtbereich.
                    </li>
                    <li>
                        <strong>Haustyp und Bauart:</strong> Wenig überraschend liegen die Kosten für Fertigteilhäuser
                        in der Regel unter denen von Massivbauhäusern. Auch der gewählte Haustyp hat einen nicht zu
                        unterschätzenden Einfluss auf den Preis.
                    </li>
                    <li>
                        <strong>Materialien und Ausstattung:</strong> Natürlich steigen die Kosten für den Hausbau auch
                        mit der Qualität von Baumaterialien und Innenausstattung sowie mit der Modernität und Effizienz
                        der Haustechnik.
                    </li>
                    <li>
                        <strong>Größe und Grundriss:</strong> Die Größe der Wohnfläche, ein komplexer Grundriss, eine
                        hohe Anzahl von Stockwerken und der Ausbau des Kellers erhöht die Kosten ebenfalls.
                    </li>
                    <li>
                        <strong>Anlagen außerhalb des Hauses:</strong> Auch die Kosten für Gärten, Garagen, Terrassen
                        und andere Nutzflächen außerhalb des Wohnraums solltest du nicht unterschätzen.
                    </li>
                </ul>
                <hr />

                <h2>Welche Kosten pro Quadratmeter muss ich beim Hausbau erwarten?</h2>
                <p>
                    Im Falle eines herkömmlichen Einfamilienhauses in einer Größe von 150 m2 schwanken die
                    Herstellungskosten in Österreich zwischen 1 750 und 2 750 Euro pro Quadratmeter – woraus sich ein
                    Mittelwert von 2 250 Euro pro Quadratmeter ergibt. Zu diesem Betrag kommen allerdings noch die
                    Kosten für den Grundstückserwerb, die{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Finanzierung
                    </Link>{" "}
                    und weitere{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Kaufnebenkosten
                    </Link>{" "}
                    hinzu, mit denen beim Immobilienerwerb zu rechnen ist.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Näheres zu den verschiedenen Kostenanteilen</h2>
                <p>
                    Die Herstellungskosten setzen sich aus dem Preis für den Roh- und dem des Innenausbaus zusammen. Ca.
                    45 % dieses Kostenpunktest wirst du für den Rohbau aufwenden. Zu diesem zählen allgemeine Bau-, Erd-
                    und konstruktive Arbeiten. Unter den allgemeinen Bauarbeiten werden folgende Tätigkeiten
                    zusammengefasst:
                </p>
                <ul>
                    <li>Die Einrichtung der Baustelle sowie ihre Sicherung, die etwa 2 % der Rohbaukosten ausmachen</li>
                    <li>Erdarbeiten und Tiefbauarbeiten mit etwa 2-4 % der Rohbaukosten</li>
                    <li>Die Entwässerung, deren Kosten sich auf 1 % beläuft</li>
                    <li>Die Gestaltung von Außenanlagen mit einem Preis, der 8-12 % der Rohbaukosten ausmacht</li>
                </ul>
                <p>Ferner zählen zu den konstruktiven Arbeiten:</p>
                <ul>
                    <li>Im Falle eines Massivbauhauses die Arbeiten von Maurer*innen (15-20% der Rohbaukosten)</li>
                    <li>Arbeiten mit Beton-/Stahlbeton, die 13-17 % ausmachen</li>
                    <li>Zimmerarbeiten (Holz, Dämmung etc.) über 5-10 %</li>
                    <li>Die Lattung des Daches, für die 15-20 % der Rohbaukosten anfallen</li>
                </ul>
                <p>
                    Im Zuge des Innenausbaus hast du schließlich die Möglichkeit, den Wohnraum nach deinen eigenen
                    Vorstellungen zu gestalten und endlich das Eigenheim einzurichten, von dem du schon immer geträumt
                    hast. Dabei fallen folgende Kosten an:
                </p>
                <ul>
                    <li>Für Putz und Estrich kannst du 15-25 Euro pro Quadratmeter einplanen</li>
                    <li>Für die Elektrik 40-100 Euro pro Quadratmeter</li>
                    <li>Für Heizung, Wasser, Gas und Strom (die Haustechnik) etwa 20-50 Euro pro Quadratmeter</li>
                    <li>
                        Für die Arbeiten eines*einer Tischler*in an Fenster, Türen, Treppen etc. zwischen 80 und 120
                        Euro pro Quadratmeter
                    </li>
                    <li>Bodenbeläge kosten dich etwa 30-50 Euro pro Quadratmeter</li>
                    <li>Maler*innenarbeiten 80-120 Euro pro Quadratmeter</li>
                    <li>
                        Die Kosten für die Innenausstattung (Küche, sanitäre Anlagen etc.) belaufen sich schließlich auf
                        20-50 Euro pro Quadratmeter
                    </li>
                </ul>
                <p>Bleiben also noch die Nebenkosten. Diese sind einmalig zu bezahlen und betragen:</p>
                <ul>
                    <li>500-800 Euro für die Baubewilligung</li>
                    <li>10 000-15 000 Euro für Vermessung und Erschließung des Grundstücks</li>
                    <li>500-1000 Euro für Zu- und Abfahrt zur Baustelle (die Baustraße)</li>
                    <li>500-1000 Euro für das Bodengutachten</li>
                    <li>Honorare für Bauleitung und Architekt*in, die etwa 9-14 % der Gesamtkosten ausmachen</li>
                    <li>Zwischen 300 und 400 Euro für die Versicherung des Bauprojektes</li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wo und wie lässt sich beim Hausbau sparen?</h2>
                <p>
                    Bei der <strong>Wahl des Haustyps:</strong>
                </p>
                <p>
                    Während Massivbauhäuser – wie es der Name schon sagt – nicht nur als witterungs-, sondern auch als
                    wertbeständiger eingeschätzt werden, kannst du mit der Entscheidung für ein Fertigteilhaus Zeit und
                    Geld sparen. Die Kosten für ein Fertighaus sind dabei vom jeweiligen Grad der Fertigstellung
                    abhängig: Ein Ausbauhaus, das du in Eigenregie vollendest, erhältst du bereits für einen Preis
                    zwischen 125 000 und 150 000 Euro. Für ein Fertighaus hingegen belaufen sich die Kosten auf etwa 1
                    750 bis 2 500 Euro pro Quadratmeter. Bei einer Wohnfläche von 150 Quadratmetern würden sich deine
                    Kosten bei einem mittelpreisigen Fertighaus damit auf etwa 300 000 bis 340 000 Euro belaufen.
                </p>
                <p>
                    Durch erhöhte <strong>Eigenleistung:</strong>
                </p>
                <p>
                    Auch, indem du beim Bau deines Hauses selbst Hand anlegst, kannst du Einsparungen erzielen. Du
                    solltest dir dabei jedoch nicht nur über die physischen und psychischen Anstrengungen im Klaren
                    sein, die das Heimwerker*innendasein mit sich bringt. Bedenke auch, dass es im Falle von
                    Unsauberkeiten zu heiklen Haftungsfragen kommen kann.
                </p>
                <p>
                    Durch den <strong>Verzicht auf den Kellerausbau:</strong>
                </p>
                <p>
                    Damit kannst du zwischen 40 000 und 50 000 Euro sparen. Da eine Unterkellerung nach der
                    Fertigstellung des Hauses jedoch nicht nur teuer ist, sondern auch statisch nicht ganz
                    unproblematisch, solltest du gründlich erwägen, ob du tatsächlich auf einen Keller verzichten
                    willst.
                </p>
                <p>
                    Bei der <strong>Innenausstattung:</strong>
                </p>
                <p>
                    Indem du eine möglichst große Zahl von Angeboten für Küche, Bad und Co. miteinander vergleichst und
                    dabei auf ein balanciertes Kosten-Nutzen-Verhältnis achtest, lassen sich auch in diesem Aspekt
                    Einsparungen vornehmen.
                </p>
                <p>
                    <strong>Beim Installieren von Außenanlagen:</strong>
                </p>
                <p>
                    Gerade Gärten, Garagen und andere Anlagen außerhalb des Wohnbereiches wachsen sich nicht selten zu
                    einem erheblichen Kostenpunkt aus. Überlege, auf welche Anlagen du verzichten kannst – oft können
                    sie zu einem späteren Zeitpunkt, etwa nach Rückzahlung des Immobilienkredits, in Angriff genommen
                    werden.
                </p>
                <hr />

                <h2>Du siehst also: Guter Rat zahlt sich aus</h2>
                <p>
                    Beim Hausbau gibt es zusätzlich zu den Kosten für das Grundstück jede Menge Nebenkosten, an die man
                    von Anfang an denken sollte. Es lohnt sich deshalb, so früh wie möglich mit einem*einer Expert*in
                    für{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Finanzierung
                    </Link>{" "}
                    zu sprechen. Diese*r kann dir wichtige Tipps geben, was langfristig nicht nur Geld, sondern auch
                    Zeit und Nerven spart.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"hausbauKosten"}></BreadcrumbList>
            <ArticleStructuredData page={"hausbauKosten"} heading={"Hausbau – Die Kosten im Überblick"} />
        </Layout>
    );
};

export default HausbauKosten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.hausbau-kosten", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
